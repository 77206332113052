<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table 
            v-else
            :items="data"
            :headers="dataHeader"
            :search="search"
            class="elevation-1"
            dense
            >
            <template v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            
            <template v-slot:item.start_at="{item}">
                <span v-if="item.start_at!=null">
                    {{ convertTimeZone(item.start_at) }}
                </span>
            </template>
            <template v-slot:item.end_at="{item}">
                <span v-if="item.end_at!=null">
                    {{ convertTimeZone(item.end_at) }}
                </span>
                <span v-else>
                    -
                </span>
            </template>
            <!-- <template v-slot:item.quantity="{item}">
                <span v-if="item.pre_order==0">
                    {{ item.quantity }}
                </span>
                <span v-else>
                    -
                </span>

            </template> -->
            <template v-slot:item.pre_order="{item}">
                <v-icon
                    v-if="item.pre_order==1" 
                    color="green" 
                    size="30" 
                    dense >
                    bi-check
                </v-icon>
            </template>
            <template v-slot:top>
                <v-toolbar flat height="autp">
                    <v-col>
                        <v-row 
                            class="mt-2 mx-3">
                            <v-col >
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                Rewards
                                </v-toolbar-title>
                            </v-col>
                            <v-col>
                                <div class="d-flex justify-end">
                                    <v-btn color="primary" :to="{name:'PageRewardCreate'}">
                                        New
                                    </v-btn>
                                </div>
                            </v-col>
                           

                        </v-row>
                        <v-row
                            class="mx-3">
                            <v-text-field
                                v-model="search"
                                label="search"
                                dense 
                                outlined>
                            </v-text-field>

                        </v-row>
                    </v-col>
                    <!-- <v-row class="ma-3">
                    </v-row> -->
                </v-toolbar>
            </template>
            <template v-slot:item.name="{item}">
                <router-link :to="{name:'PageRewardDetail',params:{id:item.id}}">
                    {{ item.name }}
                </router-link>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        search:"",
        dataHeader:[
            {
                text:'#',
                value:'Index',
                align:'start'
            },
            {
                text:'Name',
                value:'name',
            },
            {
                text:'Description',
                value:'description',
            },
            {
                text:'Required Points',
                value:'required_points',
            },
            {
                text:'Quantity',
                value:'quantity',
            },
            {
                text:'Pre order',
                value:'pre_order',
            },
            {
                text:'Priority',
                value:'order'
            },
            {
                text:'Start Date',
                value:'start_at',
            },
            {
                text:'End Date',
                value:'end_at',
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getAllRewards"){
                this.data =resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchRewardApi = this.fetchReward();
            this.$api.fetch(fetchRewardApi);
        },
        fetchReward(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/rewards";
            return tempApi;  
        },
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        },
    }
}
</script>